<template>
	<modal class="NoxModalTransliteration" name="NoxModalTransliteration" modal-width="600" :width="600" :height="'auto'" :adaptive="true" :scrollable="true" :clickToClose="true" @before-open="beforeOpen">
		<div class="nox_modal">
			<div class="nox_modal_head">
				<div class="nox_modal_close" @click="close"><font-awesome-icon :icon="['fas', 'times']" /></div>
				<div class="nox_modal_title">
					<span v-if="noxType == 'addTransliteration'">Добавление переменной транслитерации</span>
					<span v-else-if="noxType == 'editTransliteration'">Редактирование переменной транслитерации</span>
				</div>
			</div>
			<div class="nox_modal_body">
				<div class="nox_modal_info">
					<div v-if="['addTransliteration', 'editTransliteration'].includes(noxType)">
						<div class="nox_modal_info_row">
							<textarea class="nox_textarea small" v-model="noxTextRU" placeholder="Русский вариант перевода html текста"></textarea>
							<div v-html="noxAlertTextRU"></div>
						</div>
						<div class="nox_modal_info_row last">
							<textarea class="nox_textarea small" v-model="noxTextEN" placeholder="Английский вариант перевода html текста"></textarea>
							<div v-html="noxAlertTextEN"></div>
						</div>
					</div>
				</div>
				<div class="nox_modal_alert" v-html="noxAlert"></div>
				<div class="nox_modal_loading" :class="{ hide: !noxIsLoading }"><img src="@/assets/images/loading.gif"></div>
				<div class="nox_modal_buttons" :class="{ hide: noxIsLoading }">
					<button type="button" class="nox_button common" @click="close" v-html="$store.getters.getLanguageText('1.1', 3)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-if="noxType == 'addTransliteration'" v-html="$store.getters.getLanguageText('1.1', 5)"></button>
					<button type="button" class="nox_button common green" @click="axios" v-else-if="noxType == 'editTransliteration'" v-html="$store.getters.getLanguageText('1.1', 7)"></button>
				</div>
			</div>
		</div>
	</modal>
</template>

<script>
	import axios from 'axios'
	export default {
		data: () => ({
			noxTemp: '',
			noxData: {},
			noxAlert: '',
			noxAlertTextRU: '',
			noxAlertTextEN: '',
			noxUId: 0,
			noxType: '',
			noxIndex: 0,
			noxTextRU: '',
			noxTextEN: '',
			noxIsLoading: false,
			noxIsError: false
		}),
		methods: {
			beforeOpen: function(event) {

				this.noxTemp = '';
				this.noxData = {};
				this.resetAlerts();
				this.noxUId = event.params.uid;
				this.noxType = event.params.type;
				this.noxIndex = event.params.index;
				this.noxTextRU = '';
				this.noxTextEN = '';
				this.noxIsLoading = false;
				this.noxIsError = false;

				if (this.noxType == 'editTransliteration') {
					if (this.noxUId == 1) {
						this.noxData = this.$parent.noxTransliterationDataFromLanguage[this.noxIndex];
					}
					else if (this.noxUId == 2) {
						this.noxData = this.$parent.noxTransliterationDataFromDatabase[this.noxIndex];
					}
					if (Object.keys(this.noxData).length) {
						this.noxTextRU = this.noxData.ru;
						this.noxTextEN = this.noxData.en;
					}
				}
			},
			resetAlerts: function() {

				this.noxAlert = '';
				this.noxAlertTextRU = '';
				this.noxAlertTextEN = '';
			},
			getError: function(i) {

				this.noxIsError = true;

				if      (i == 1) { this.noxTemp = 'Необходимо указать uid переменной транслитерации.'; }
				else if (i == 2) { this.noxTemp = 'Необходимо указать верный uid переменной транслитерации.'; }
				else if (i == 3) { this.noxTemp = 'Необходимо указать тип переменной транслитерации.'; }
				else if (i == 4) { this.noxTemp = 'Необходимо указать верный тип переменной транслитерации.'; }
				else if (i == 5) { this.noxTemp = 'Необходимо указать индекс переменной транслитерации.'; }
				else if (i == 6) { this.noxTemp = 'Необходимо указать верный индекс переменной транслитерации.'; }
				else if (i == 7) { this.noxTemp = 'Необходимо указать русский вариант перевода html текста.'; }
				else if (i == 8) { this.noxTemp = 'Необходимо указать верный русский вариант перевода html текста.'; }
				else if (i == 9) { this.noxTemp = 'Необходимо указать английский вариант перевода html текста.'; }
				else if (i == 10) { this.noxTemp = 'Необходимо указать верный английский вариант перевода html текста.'; }
				else if (i == 11) { this.noxTemp = 'Тип и индекс переменной транслитерации не найдены в системе.'; }
				else              { this.noxTemp = 'Внутренняя ошибка сервера.'; }

				return '<div class="alert"><b>' + this.$store.getters.getLanguageText('1.3', 0) + '</b> ' + this.noxTemp + '</div>';
			},
			axios: function() {

				var _this = this;
				if (localStorage.getItem('noxToken')) {

					var config = {
						url: '/v2/admin/account/transliteration',
						method: 'get', baseURL: _this.$store.state.noxApiBaseURL,
						headers: { 'Authorization': 'Bearer ' + localStorage.getItem('noxToken') }
					};

					_this.resetAlerts();
					_this.noxIsError = false;

					if (['addTransliteration', 'editTransliteration'].includes(_this.noxType)) {
						if (!_this.noxTextRU) {
							_this.noxAlertTextRU = _this.getError(7);
						}
						if (!_this.noxTextEN) {
							_this.noxAlertTextEN = _this.getError(9);
						}
						if (!_this.noxIsError) {
							config.data = { uid: _this.noxUId, type: _this.$parent.noxTransliterationFilter.code, index: _this.noxIndex, text_ru: _this.noxTextRU, text_en: _this.noxTextEN };
							config.method = _this.noxType == 'addTransliteration' ? 'post' : 'patch';
						}
					}

					if (!_this.noxIsError) {
						_this.noxIsLoading = true;
						axios(config).then(function(data) {
							if (data.status == 200) {
								if (_this.noxType == 'addTransliteration') {
									if (_this.noxUId == 1) {
										_this.$parent.noxTransliterationDataFromLanguage.push({ ru: _this.noxTextRU, en: _this.noxTextEN });
									}
									else if (_this.noxUId == 2) {
										_this.$parent.noxTransliterationDataFromDatabase.push({ ru: _this.noxTextRU, en: _this.noxTextEN });
									}
								}
								else if (_this.noxType == 'editTransliteration') {
									if (_this.noxUId == 1) {
										_this.$parent.noxTransliterationDataFromLanguage.splice(_this.noxIndex, 1, { ru: _this.noxTextRU, en: _this.noxTextEN });
									}
									else if (_this.noxUId == 2) {
										_this.$parent.noxTransliterationDataFromDatabase.splice(_this.noxIndex, 1, { ru: _this.noxTextRU, en: _this.noxTextEN });
									}
								}
								_this.close();
							}
						}).catch(function(data) {
							if (data.response !== undefined) {
								if (data.response.status == 400) {
									if (['addTransliteration', 'editTransliteration'].includes(_this.noxType)) {
										if      (data.response.data.error == 'UID_IS_EMPTY') { _this.noxAlert = _this.getError(1); }
										else if (data.response.data.error == 'UID_NOT_VALID') { _this.noxAlert = _this.getError(2); }
										else if (data.response.data.error == 'TYPE_IS_EMPTY') { _this.noxAlert = _this.getError(3); }
										else if (data.response.data.error == 'TYPE_NOT_VALID') { _this.noxAlert = _this.getError(4); }
										else if (data.response.data.error == 'INDEX_IS_EMPTY') { _this.noxAlert = _this.getError(5); }
										else if (data.response.data.error == 'INDEX_NOT_VALID') { _this.noxAlert = _this.getError(6); }
										else if (data.response.data.error == 'TEXT_RU_IS_EMPTY') { _this.noxAlertTextRU = _this.getError(7); }
										else if (data.response.data.error == 'TEXT_RU_NOT_VALID') { _this.noxAlertTextRU = _this.getError(8); }
										else if (data.response.data.error == 'TEXT_EN_IS_EMPTY') { _this.noxAlertTextEN = _this.getError(9); }
										else if (data.response.data.error == 'TEXT_EN_NOT_VALID') { _this.noxAlertTextEN = _this.getError(10); }
									}
								}
								else if (data.response.status == 401) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 403) { _this.$parent.$parent.$parent.exit(); }
								else if (data.response.status == 404) {
									if (_this.noxType == 'editTransliteration') {
										if (data.response.data.error == 'TYPE_AND_INDEX_NOT_FOUND') { _this.noxAlert = _this.getError(11); }
									}
								}
							}
							if (!_this.noxIsError) {
								_this.noxAlert = _this.getError(0);
							}
						}).finally(function() {
							_this.noxIsLoading = false;
						});
					}
				}
				else {
					_this.$parent.$parent.$parent.exit();
				}
			},
			close: function() {
				this.$modal.hide('NoxModalTransliteration');
			}
		}
	}
</script>
